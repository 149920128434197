import { Box, TableBody, TableCell, TableHeader, TableRow } from "grommet";
import { useInView } from "react-intersection-observer";
import { Fragment, memo } from "react";
import dynamic from "next/dynamic";
import { useCoin } from "@organisms/coin/provider";
import { DesktopName, Name } from "./name";
import Icon from "./icon";
import Launched from "./launched";
import RecentVotes from "./recentVotes";
import CellLink from "./cellLink";
import Container from "./container";
import Watchlist from "./watchlist";
import Header, { IconHeader, PriceHeader } from "./table/header";

const Badges = dynamic(() => import("./badges"));
const Vote = dynamic(() => import("./vote"));
const TokensWon = dynamic(() => import("./tokensWon"));
const ValueInUsd = dynamic(() => import("./valueInUsd"));
const SumRow = dynamic(() => import("./sumRow"));
const PresaleCap = dynamic(() => import("./presale/cap"));
const PresaleBadge = dynamic(() => import("./presale/badge"));
const Marketcap = dynamic(() => import("./marketcap"));
const PriceChange = dynamic(() => import("./priceChange"));

function Row({
  airdrop,
  gaCategory,
  slug,
  rank,
  firstIndex,
  showVotesToday,
  index,
  navigate,
}) {
  const cellLinkProps = { slug, navigate, gaCategory, index };
  const hasPresale = useCoin(
    slug,
    ({ marketcap, presale }) => presale && !marketcap
  );

  return (
    <Fragment>
      <TableCell key="watchlist" size="50px" align="center" pad="small">
        <Watchlist slug={slug} rank={rank} index={firstIndex + index + 1} />
      </TableCell>
      <TableCell
        key="name"
        align="left"
        direction="row"
        gap="xsmall"
        margin={{ left: "none" }}
        width={{ max: "350px" }}
      >
        <Icon slug={slug} />
        <DesktopName slug={slug} />
        <CellLink {...cellLinkProps} />
      </TableCell>
      <TableCell size="100px" key="launched" align="center">
        <Launched slug={slug} />
        <CellLink {...cellLinkProps} />
      </TableCell>
      <TableCell size="150px" key="price" align="center">
        {hasPresale && <PresaleCap slug={slug} />}
        {!hasPresale && <PriceChange slug={slug} />}
        <CellLink {...cellLinkProps} />
      </TableCell>
      <TableCell
        size={`${showVotesToday ? 130 : 160}px`}
        key="marketcap"
        align="center"
      >
        {hasPresale && <PresaleBadge slug={slug} />}
        {!hasPresale && <Marketcap slug={slug} />}
        <CellLink {...cellLinkProps} />
      </TableCell>
      {airdrop && (
        <TableCell size="200px" key="Tokens per Winner" align="center">
          <TokensWon slug={slug} />
          <CellLink {...cellLinkProps} />
        </TableCell>
      )}
      {!airdrop && (
        <TableCell size="200px" key="badges" align="center">
          <Badges slug={slug} />
          <CellLink {...cellLinkProps} />
        </TableCell>
      )}
      {showVotesToday && (
        <TableCell size="66px" key="recentVotes" align="center">
          <RecentVotes slug={slug} />
          <CellLink {...cellLinkProps} />
        </TableCell>
      )}
      {airdrop && (
        <TableCell key="valueInUsd" size="220px" align="center">
          <ValueInUsd slug={slug} />
          <CellLink {...cellLinkProps} />
        </TableCell>
      )}
      {!airdrop && (
        <TableCell
          key="votes"
          size={`${showVotesToday ? 170 : 220}px`}
          align="center"
        >
          <Vote slug={slug} />
          <CellLink {...cellLinkProps} />
        </TableCell>
      )}
    </Fragment>
  );
}

function PlaceholderRow({ slug, navigate, showVotesToday }) {
  return (
    <Fragment>
      <TableCell
        key="watchlist"
        size="50px"
        align="center"
        pad="small"
      ></TableCell>
      <TableCell key="name" align="left">
        <Name slug={slug} />
        <CellLink slug={slug} navigate={navigate} />
      </TableCell>
      <TableCell colSpan={showVotesToday ? 9 : 8} />
    </Fragment>
  );
}

function RowContainer({ gaCategory, index, slug, initialVisible, ...rest }) {
  const exist = useCoin(slug, (state) => Boolean(state));
  const skip = initialVisible === -1 || index < initialVisible;

  const { ref, inView } = useInView({
    rootMargin: "100px 0px",
    triggerOnce: true,
    skip,
  });

  return (
    <TableRow style={{ height: "72px" }} ref={ref} key={slug} round>
      {exist && (inView || skip) ? (
        <Row slug={slug} index={index} gaCategory={gaCategory} {...rest} />
      ) : (
        <PlaceholderRow slug={slug} {...rest} />
      )}
    </TableRow>
  );
}

const RowContainerMemo = memo(RowContainer);

export default function Desktop({
  slugs,
  promoted = false,
  airdrop = false,
  rank = true,
  firstIndex = 0,
  showVotesToday,
  navigate,
  initialVisible = -1,
  gaCategory,
  ...rest
}) {
  return (
    <Box overflow={{ horizontal: "auto" }}>
      <Container hasHeader elevation="xlarge" promoted={promoted} {...rest}>
        <TableHeader>
          <TableRow>
            {promoted ? (
              <IconHeader
                id="watchlist"
                promoted={promoted}
                label="Promoted Coins"
                direction="row"
                margin={{ bottom: "small" }}
              />
            ) : (
              <Header
                key="watchlist"
                label={rank ? "#" : ""}
                pad={rank ? { horizontal: "small" } : ""}
                align="left"
              />
            )}
            <Header key="name" label={rank ? "Coin" : ""} align="left" />
            <Header key="launched" label="Launch" />
            <PriceHeader key="price" />
            <Header key="marketcap" label="Market Cap" />
            {airdrop && <Header key="tokensWon" label="Tokens Won" />}
            {!airdrop && <Header key="badges" label="Badges" />}
            {showVotesToday && <Header key="recentVotes" label="Today" />}
            {airdrop && <Header key="valueInUsd" label="Value In USD" />}
            {!airdrop && <Header key="votes" label="Votes" />}
          </TableRow>
        </TableHeader>
        <TableBody>
          {slugs.map((slug, index) => (
            <RowContainerMemo
              initialVisible={initialVisible}
              key={slug}
              slug={slug}
              rank={rank}
              firstIndex={firstIndex}
              showVotesToday={showVotesToday}
              index={index}
              navigate={navigate}
              gaCategory={gaCategory}
              airdrop={airdrop}
              promoted={promoted}
            />
          ))}
          {airdrop && (
            <SumRow initialVisible={initialVisible} index={slugs.length} />
          )}
        </TableBody>
      </Container>
    </Box>
  );
}
