import { Box, TableCell } from "grommet";
import styled from "styled-components";
import StyledText from "components/text";
import Image from "next/legacy/image";
import PromotedIcon from "public/assets/coinlist/promote.svg";

const StyledLabel = styled(Box)`
  position: absolute;
`;

const StyledTableCell = styled(TableCell)`
  vertical-align: top;
`;

export function IconHeader({ id, label, ...rest }) {
  return (
    <StyledTableCell key={id} scope="col" {...rest}>
      <StyledLabel
        margin={{ left: "small" }}
        direction="row"
        justify="center"
        gap="xsmall"
      >
        <Image alt="Promoted Label Icon" src={PromotedIcon} />
        <StyledText color="light-1" weight={400}>
          {label}
        </StyledText>
      </StyledLabel>
    </StyledTableCell>
  );
}

export function PriceHeader({ id, ...rest }) {
  return (
    <TableCell key={id} scope="col" {...rest}>
      <Box direction="row" justify="between" fill>
        <StyledText color="dark-4" weight={400}>
          1h
        </StyledText>
        <StyledText color="dark-4" weight={400}>
          24h
        </StyledText>
      </Box>
    </TableCell>
  );
}

export default function Header({
  id,
  label,
  size = "small",
  align = "center",
  ...rest
}) {
  return (
    <TableCell key={id} scope="col" align={align} {...rest}>
      <StyledText color="dark-4" size={size} weight={400}>
        {label}
      </StyledText>
    </TableCell>
  );
}
