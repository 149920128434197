export default function utc0(value) {
  const date = new Date(value);
  return date.getTime() + date.getTimezoneOffset() * 60 * 1000;
}

export function utc0Date(value) {
  return new Date(utc0(value));
}

export function serverOtc0Date(value) {
  const date = new Date(value);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}
