import router from "next/router";
import firebase from "@helpers/firebase";

export default function login(loginId) {
  return new Promise((resolve, reject) => {
    const context = {};
    router.replace({ hash: `login&${loginId}` });

    const onHashChange = () => {
      if (context.unsubscribe) context.unsubscribe();

      if (!firebase.auth().currentUser) return reject();

      resolve();
    };

    context.unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) onHashChange();
    });
  });
}
